import React from 'react'

const Home = () => {
  return (
    <div>
        <div className="main-page-wrapper">
			{/* <!-- 
			=============================================
				Theme Hero Banner
			============================================== 
			--> */}
			<div className="hero-banner-five">
				<div className="container">
					<div className="row">
						<div className="col-xxl-6 col-md-7">
							<h1 className="hero-heading">Customer Ai Solution for user <span>Analystics.</span></h1>
							<p className="text-lg mb-50 pt-40 pe-xl-5 md-pt-30 md-mb-40">Sinco delivered blazing fast, striking ai solution lorem quis data user learning analysis.</p>
							<ul className="style-none button-group d-flex align-items-center">
								<li className="me-4"><a href="contact-us.html" className="demo-btn ripple-btn tran3s">Request A Demo</a></li>
								<li><a className="fancybox video-icon tran3s" data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0"><i className="fas fa-play"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="illustration-holder">
					<img src="assets/images/assets/ils_13.svg" alt="" className="main-illustration ms-auto"/>
					<img src="assets/images/assets/ils_13_1.svg" alt="" className="shapes shape-one"/>
					<img src="assets/images/assets/ils_13_2.svg" alt="" className="shapes shape-two" data-aos="fade-down"/>
					<img src="assets/images/assets/ils_13_2.svg" alt="" className="shapes shape-three" data-aos="fade-down"/>
				</div>
				<div className="shapes oval-one"></div>
			</div>

			


			{/* <!-- 
			=============================================
				Feature Section Seventeen
			============================================== 
			--> */}
			<div className="fancy-feature-seventeen position-relative mt-160 xl-mt-50">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-xl-6 col-lg-5" data-aos="fade-right">
							<div className="title-style-three text-center text-lg-start">
								<h2 className="main-title"><span>Services</span> We Provide with Quality.</h2>
							</div>
						</div>
						<div className="col-xl-6 col-lg-7" data-aos="fade-left">
							<p className="m0 text-center text-lg-start md-pt-30">NLP entails applying algorithms to identify and extract rules such that the unstructured language data is converted.</p>
						</div>
					</div>

					<div className="row justify-content-center pt-30">
						<div className="col-lg-4 col-md-6" data-aos="fade-right">
							<div className="block-style-twelve mt-30 ps-lg-0 pe-xl-5 me-xl-2">
								<div className="icon d-flex align-items-end"><img src="assets/images/icon/icon_31.svg" alt=""/></div>
								<h5><a href="service-details-V1.html">Data Science</a></h5>
								<p>Convert data noise intelligent insights for competitive differentiation qulaity check equlity.</p>
								<a href="service-details-V1.html" className="tran3s more-btn"><img src="assets/images/icon/icon_20.svg" alt=""/></a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
							<div className="block-style-twelve mt-30 active">
								<div className="icon d-flex align-items-end"><img src="assets/images/icon/icon_32.svg" alt=""/></div>
								<h5><a href="service-details-V1.html">Customer Support</a></h5>
								<p>Convert data noise to intelligent insights for competitive differentiation qulaity check.</p>
								<a href="service-details-V1.html" className="tran3s more-btn"><img src="assets/images/icon/icon_20.svg" alt=""/></a>
							</div> 
						</div>
						<div className="col-lg-4 col-md-6" data-aos="fade-left">
							<div className="block-style-twelve mt-30">
								<div className="icon d-flex align-items-end"><img src="assets/images/icon/icon_33.svg" alt=""/></div>
								<h5><a href="service-details-V1.html">Machine Learning</a></h5>
								<p>Convert data noise intelligent insights for competitive differentiation qulaity check equlity.</p>
								<a href="service-details-V1.html" className="tran3s more-btn"><img src="assets/images/icon/icon_20.svg" alt=""/></a>
							</div> 
						</div> 
					</div>
				</div>
				<div className="shapes shape-one"></div>
			</div> 
			


			{/* <!-- 
			=============================================
				Feature Section Eighteen
			============================================== 
			--> */}
			<div className="fancy-feature-eighteen position-relative pt-200 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
							<div className="block-style-two" data-aos="fade-left">
								<div className="title-style-three">
									<div className="sc-title">Over 150k+ Client</div>
									<h2 className="main-title">Target <span>Audience</span> for your business.</h2>
								</div> 
								<p className="pt-20 pb-25 lg-pb-20">AI technology is perfect for best business solutions & we offer help to achieve your goals.</p>
								<ul className="style-none list-item color-rev">
									<li>Various analysis options.</li>
									<li>Page Load (time, size, number of requests).</li>
									<li>Advance Data analysis operation.</li>
								</ul>
								<a href="about-us2.html" className="btn-eight mt-50 lg-mt-30">More about us</a>
							</div>
						</div>
					</div>
				</div>
				<div className="illustration-holder" data-aos="fade-right">
					<img src="assets/images/assets/ils_14.svg" alt="" className="w-100 main-illustration"/>
					<img src="assets/images/assets/ils_14_1.svg" alt="" className="shapes shape-one" data-aos="fade-down"/>
					<img src="assets/images/assets/ils_14_2.svg" alt="" className="shapes shape-two" data-aos="fade-down" data-aos-delay="100"/>
					<img src="assets/images/assets/ils_14_3.svg" alt="" className="shapes shape-three" data-aos="fade-down" data-aos-delay="200"/>
					<img src="assets/images/assets/ils_14_4.svg" alt="" className="shapes shape-four"/>
					<img src="assets/images/assets/ils_14_5.svg" alt="" className="shapes shape-five"/>
					<img src="assets/images/assets/ils_14_6.svg" alt="" className="shapes shape-six"/>
					<img src="assets/images/assets/ils_14_7.svg" alt="" className="shapes shape-seven"/>
				</div>
				<div className="shapes oval-one"></div>
				<div className="shapes oval-two"></div>
				<div className="shapes oval-three"></div>
			</div> 



			{/* <!-- 
			=============================================
				Feature Section Nineteen
			============================================== 
			--> */}
			<div className="fancy-feature-nineteen position-relative pt-130 lg-pt-80">
				<div className="container">
					<div className="row">
						<div className="col-xxl-5 col-lg-6 col-md-7">
							<div className="block-style-thirteen" data-aos="fade-right">
								<div className="title-style-three pb-15">
									<div className="sc-title">WORK PROCESS</div>
									<h2 className="main-title">How sinco <span>process</span> & works.</h2>
								</div>
								<ul className="style-none list-item">
									<li data-aos="fade-up">
										<div className="numb tran3s">1</div>
										<h6>Frame the Problem</h6>
										<span>Learn content by interacting with an expert lesson or watching a video.</span>
									</li>
									<li data-aos="fade-up" data-aos-delay="50">
										<div className="numb tran3s">2</div>
										<h6>Collect the Data</h6>
										<span>Practice what you learned on realistic SAT test questions.</span>
									</li>
									<li data-aos="fade-up" data-aos-delay="100">
										<div className="numb tran3s">3</div>
										<h6>Process the Data</h6>
										<span>Have to be repudiated annoyances accepted the wise lorem ispum sample text.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div> 
				<div className="illustration-holder" data-aos="fade-left">
					<img src="assets/images/assets/ils_15.svg" alt="" className="w-100 main-illustration"/>
					<img src="assets/images/assets/ils_15_1.svg" alt="" className="shapes shape-one"/>
					<img src="assets/images/assets/ils_15_2.svg" alt="" className="shapes shape-two"/>
					<img src="assets/images/assets/ils_15_3.svg" alt="" className="shapes shape-three"/>
					<img src="assets/images/assets/ils_15_4.svg" alt="" className="shapes shape-four"/>
					<img src="assets/images/assets/ils_15_5.svg" alt="" className="shapes shape-five" data-aos="fade-down" data-aos-delay="200" data-aos-duration="2000"/>
					<img src="assets/images/assets/ils_15_6.svg" alt="" className="shapes shape-six" data-aos="fade-down" data-aos-delay="100" data-aos-duration="2000"/>
					<img src="assets/images/assets/ils_15_7.svg" alt="" className="shapes shape-seven" data-aos="fade-down" data-aos-duration="2000"/>
				</div>
				<div className="shapes oval-one"></div>
				<div className="shapes oval-two"></div>
				<img src="assets/images/shape/shape_35.svg" alt="" className="shapes bg-shape"/>
			</div> 




			{/* <!--
			=====================================================
				Counter Section One
			=====================================================
			--> */}
			<div className="counter-section-one mt-140 lg-mt-100">
				<div className="container">
					<div className="inner-container bg-color">
						<div className="row justify-content-center">
							<div className="col-md-4 col-sm-6" data-aos="fade-up">
								<div className="counter-block-one text-center mb-20">
									<div className="main-count"><span className="counter">20</span>mil</div>
									<p>Human labor hours saved <br/> with help of AI</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
								<div className="counter-block-one text-center mb-20">
									<div className="main-count"><span className="counter">1.3</span>b+</div>
									<p>Generated revenue by <br/>AI Solutions</p>
								</div> 
							</div>
							<div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
								<div className="counter-block-one text-center mb-20">
									<div className="main-count">$<span className="counter">15</span>mil+</div>
									<p>Saved operational costs <br/>due to AI</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!--
			=====================================================
				Feature Section Twenty
			=====================================================
			--> */}
			<div className="fancy-feature-twenty position-relative mt-160 pb-100 lg-mt-100 lg-pb-70">
				<div className="container">
					<div className="row">
						<div className="col-lg-5">
							<div className="block-style-five pe-xxl-5 me-xxl-5 md-pb-50" data-aos="fade-right">
								<div className="title-style-three">
									<div className="sc-title">QUESTIONS & ANSWERS</div>
									<h2 className="main-title">Any <span>Questions?</span> Find here.</h2>
								</div>
								<p className="pt-20 pb-15">Don’t find your answer here? just send us a message for any query.</p>
								<a href="contact-us.html" className="btn-eight ripple-btn">Contact us</a>
							</div>
						</div>

						<div className="col-lg-7" data-aos="fade-left">
							<div className="accordion accordion-style-one" id="accordionOne">
								<div className="accordion-item">
								    <div className="accordion-header" id="headingOne">
								      	<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
								        	What is web hosting?
								      	</button>
								    </div>
								    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionOne">
								    	<div className="accordion-body">
								        	<p>They not only understand what I say but read between the lines and also give me ideas of my own. AI technology is perfect for best business solutions. </p>
								    	</div>
								    </div>
							  	</div>
							  	<div className="accordion-item">
								    <div className="accordion-header" id="headingTwo">
								      	<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								        	How do you weigh different criteria in your process?
								      	</button>
								    </div>
								    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
								      	<div className="accordion-body">
								        	<p>They not only understand what I say but read between the lines and also give me ideas of my own. AI technology is perfect for best business solutions. </p>
								    	</div>
								    </div>
							  	</div>
							  	<div className="accordion-item">
								    <div className="accordion-header" id="headingThree">
								   		<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								        	What can I use to build my website?
								      	</button>
								    </div>
								    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionOne">
								    	<div className="accordion-body">
								        	<p>They not only understand what I say but read between the lines and also give me ideas of my own. AI technology is perfect for best business solutions. </p>
								    	</div>
								    </div>
								</div>
								<div className="accordion-item">
								    <div className="accordion-header" id="headingFour">
								   		<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
								        	If I already have a website, can I transfer it to your web hosting?
								      	</button>
								    </div>
								    <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionOne">
								    	<div className="accordion-body">
								        	<p>They not only understand what I say but read between the lines and also give me ideas of my own. AI technology is perfect for best business solutions. </p>
								    	</div>
								    </div>
								</div>
								<div className="accordion-item">
								    <div className="accordion-header" id="headingFive">
								   		<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
								        	How can I accept credit cards online?
								      	</button>
								    </div>
								    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionOne">
								    	<div className="accordion-body">
								        	<p>They not only understand what I say but read between the lines and also give me ideas of my own. AI technology is perfect for best business solutions. </p>
								    	</div>
								    </div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img src="images/shape/shape_36.svg" alt="" className="shapes shape-one"/>
				<div className="shapes oval-one"></div>
			</div>

			{/* <!--
			=====================================================
				Feature Section Twenty One
			=====================================================
			--> */}
			<div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-7 ms-auto">
							<div className="block-style-five ps-xxl-5" data-aos="fade-left">
								<div className="title-style-three">
									<div className="sc-title">Contact us</div>
									<h2 className="main-title">Get Ready to Started. It’s Fast & Easy.</h2>
								</div>
								<p className="pt-20 pb-15">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut  quis nostrud ullamco laboris nisi.</p>
								<a href="contact-us.html" className="btn-eight ripple-btn">Send Message</a>
							</div> 
						</div>
					</div>
				</div>
				<div className="illustration-holder" data-aos="fade-right">
					<img src="assets/images/assets/ils_16.svg" alt="" className="w-100 main-illustration"/>
				</div>
				<img src="assets/images/shape/shape_37.svg" alt="" className="shapes shape-one"/>
				<div className="shapes oval-one"></div>
				<div className="shapes oval-two"></div>
			</div>
        </div>
    </div>
  )
}

export default Home