import React from 'react'

const Header = () => {
  return (
    <div>
        <section>
            <div id="preloader">
                <div id="ctn-preloader" className="ctn-preloader">
                    <div className="animation-preloader">
                        <div className="icon"><img src="assets/images/logo/logo_loader.png" alt="" className="m-auto d-block" width="152"/></div>
                        <div className="txt-loading mt-2">
                            <span data-text-preloader="M" className="letters-loading">
                                M
                            </span>
                            <span data-text-preloader="U" className="letters-loading">
                                U
                            </span>
                            <span data-text-preloader="L" className="letters-loading">
                                L
                            </span>
                            <span data-text-preloader="T" className="letters-loading">
                                T
                            </span>
                            <span data-text-preloader="I" className="letters-loading">
                                I
                            </span>
                            <span data-text-preloader="B" className="letters-loading">
                                B
                            </span>
                            <span data-text-preloader="A" className="letters-loading">
                                A
                            </span>
                            <span data-text-preloader="R" className="letters-loading">
                                R
                            </span>
                            <span data-text-preloader="R" className="letters-loading">
                                R
                            </span>
                            <span data-text-preloader="E" className="letters-loading">
                                E
                            </span>
                            <span data-text-preloader="L" className="letters-loading">
                                L
                            </span>
                        </div>
                    </div>	
                </div>
            </div>
        </section>


        {/* <!-- 
        =============================================
            Search
        ============================================== 
        --> */}
        <div className="offcanvas offcanvas-top theme-search-form bg-three justify-content-center" tabindex="-1" id="offcanvasTop">
            <button type="button" className="close-btn tran3s" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            <div className="form-wrapper">
                <form action="#">
                    <input type="text" placeholder="Search Keyword...."/>
                </form>
            </div>
        </div>


        {/* <!-- 
        =============================================
            Theme Main Menu
        ============================================== 
        --> */}
        <header className="theme-main-menu sticky-menu theme-menu-four">
            <div className="inner-content">
                <div className="d-flex align-items-center">
                    <div className="logo order-lg-0 head-logo"><a href="index.html" className="d-block"><img src="assets/images/logo/logo_head.png" alt="" width="200"/></a></div>

                    <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
                        <div className="call-button d-none d-xl-block me-5">Call us <a href="#">(+880) 321 782 110</a></div>
                        <button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><i className="bi bi-search"></i></button>
                        <a href="contact-us.html" className="send-msg-btn tran3s d-none d-lg-block">Send us Message</a>
                    </div> 

                    <nav className="navbar navbar-expand-lg order-lg-2">
                        <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="d-block d-lg-none"><div className="logo"><a href="index.html"><img src="assets/images/logo/logo_01.png" alt="" width="130"/></a></div></li>
                                <li className="nav-item active">
                                    <a className="nav-link" href="/" role="button" >Home</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Services</a>
                                    <ul className="dropdown-menu">
                                        <li><a href="/services" className="dropdown-item"><span>Service 01</span></a></li>
                                        <li><a href="portfolio-V2.html" className="dropdown-item"><span>Service 02</span></a></li>
                                        <li><a href="portfolio-V3.html" className="dropdown-item"><span>Service 03</span></a></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/software" role="button">Software</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/wordpress" role="button">WordPress</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/contact">Contact</a>
                                </li>
                            </ul>
                            
                            <div className="mobile-content d-block d-lg-none">
                                <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                                    <a href="contact-us.html" className="send-msg-btn tran3s mb-10">Send us Message</a>
                                    <div className="call-button">Call us <a href="#">(+880) 321 782 110</a></div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    </div>
  )
}

export default Header