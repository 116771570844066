import React from 'react'

function footer() {
  return (
    <div>
        <div class="footer-style-one bg-color theme-basic-footer">
            <div class="container">
                <div class="inner-wrapper">
                    <div class="row">
                        <div class="col-lg-3 footer-intro mb-40">
                            <div class="logo"><a href="index.html"><img src="assets/images/logo/logo_01.png" alt="" width="130"/></a></div>
                            <p>We will assist you in becoming more successful.</p>
                            <ul class="d-flex social-icon style-none">
                                <li><a href="/"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="/"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="/"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-6 mb-30">
                            <h5 class="footer-title">Links</h5>
                            <ul class="footer-nav-link style-none">
                                <li><a href="index.html">Home</a></li>
                                <li><a href="pricing.html">Pricing</a></li>
                                <li><a href="about-us2.html">About us</a></li>
                                <li><a href="service-V1.html">Service</a></li>
                                <li><a href="blog-V1.html">Blog</a></li>
                            </ul>
                        </div>
                        <div class="col-xl-2 col-md-3 col-sm-6 mb-30">
                            <h5 class="footer-title">Legal</h5>
                            <ul class="footer-nav-link style-none">
                                <li><a href="faq.html">Terms of use</a></li>
                                <li><a href="faq.html">Terms & conditions</a></li>
                                <li><a href="faq.html">Privacy policy</a></li>
                                <li><a href="faq.html">Cookie policy</a></li>
                            </ul>
                        </div>
                        <div class="col-xl-5 col-lg-4 col-md-6 mb-30">
                            <div class="newsletter ps-xl-5">
                                <h5 class="footer-title">Subscribe </h5>
                                <p>Join over <span>68,000</span> people getting our emails</p>
                                <form action="#">
                                    <input type="email" placeholder="Enter your email"/>
                                    <button>Sign Up</button>
                                </form>
                                <div class="info">We only send interesting and relevant emails.</div>
                            </div>
                        </div>
                    </div>

                    <div class="bottom-footer">
                        <div class="d-lg-flex justify-content-between align-items-center">
                            <ul class="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                <li><a href="faq.html">Privacy &amp; Terms.</a></li>
                                <li><a href="faq.html">FAQ</a></li>
                                <li><a href="contact-us.html">Contact Us</a></li>
                            </ul>
                            <p class="copyright text-center order-lg-0 pb-15">Copyright @2022 sinco inc.</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/images/shape/shape_44.svg" alt="" class="shapes shape-one"/>
            <img src="assets/images/shape/shape_45.svg" alt="" class="shapes shape-two"/>
        </div>

		<button className="scroll-top">
			<i className="bi bi-arrow-up-short"></i>
		</button>

    </div>
  )
}

export default footer