import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Software from './pages/Software';
import Wordpress from './pages/Wordpress';
import Services from './pages/Services';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div>
      <Router>
        <Header/>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/software' element={<Software />} />
          <Route exact path='/wordpress' element={<Wordpress />} />
          <Route exact path='/services' element={<Services />} />
          <Route exact path='/contact' element={<Contact />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
