import React from 'react'

function Software() {
  return (
    <div>  
    {/* <!-- 
    =============================================
      Theme Inner Banner
    ============================================== 
    --> */}
    <div className="theme-inner-banner">
      <div className="container">
        <h2 className="intro-title text-center">Software Development</h2>
        <ul className="page-breadcrumb style-none d-flex justify-content-center">
          <li><a href="index.html">Home</a></li>
          <li className="current-page">About us</li>
        </ul>
      </div>
      <img src="assets/images/shape/shape_38.svg" alt="" className="shapes shape-one"/>
      <img src="assets/images/shape/shape_39.svg" alt="" className="shapes shape-two"/>
    </div>



    {/* <!-- 
    =============================================
      Feature Section Two
    ============================================== 
    --> */}
    <div className="fancy-feature-two position-relative mt-140 lg-mt-100">
      <div className="container">
        <div className="row">
          <div className="col-xxl-5 col-lg-6 col-md-7 ms-auto">
            <div className="block-style-two" data-aos="fade-left">
              <div className="title-style-one">
                <div className="sc-title-four">WHO WE ARE</div>
                <h2 className="main-title">World best AI for your business. </h2>
              </div>
              <p className="pt-10 pb-20 lg-pb-10">AI technology is perfect for best business solutions & we offer help to achieve your goals.</p>
              <ul className="style-none list-item color-rev">
                <li>Various analysis options.</li>
                <li>Page Load (time, size, number of requests).</li>
                <li>Big data analysis.</li>
                <li>Adance Data analysis operation.</li>
              </ul>
              <a href="contact-us.html" className="btn-one mt-30">Contact us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="illustration-holder-two sm-mt-40">
        <img src="assets/images/assets/ils_05.svg" alt="" className="main-illustration w-100"/>
        <img src="assets/images/assets/ils_05_1.svg" alt="" className="shapes shape-one"/>
        <img src="assets/images/assets/ils_05_2.svg" alt="" className="shapes shape-two" data-aos="fade-up" data-aos-anchor=".fancy-feature-two" data-aos-delay="100" data-aos-duration="2000"/>
        <img src="assets/images/assets/ils_05_3.svg" alt="" className="shapes shape-three" data-aos="fade-up" data-aos-anchor=".fancy-feature-two" data-aos-delay="150" data-aos-duration="2000"/>
        <img src="assets/images/assets/ils_05_4.svg" alt="" className="shapes shape-four"/>
        <img src="assets/images/assets/ils_05_5.svg" alt="" className="shapes shape-five"/>
      </div>
    </div>



    {/* <!-- 
    =============================================
      Feature Section Twenty Two
    ============================================== 
    --> */}
    <div className="fancy-feature-twentyTwo mt-150 lg-mt-60">
      <div className="container">
        <div className="row gx-xxl-5">
          <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
            <div className="block-style-fourteen mt-35">
              <h5>Our History</h5>
              <p>Convert data noise to intelligent insights for competitive differention qulaity check.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
            <div className="block-style-fourteen mt-35">
              <h5>Our Mission</h5>
              <p>Except to obtain some advantage from it? But who has any right to find fault with a consequences for competitive differention qulaity.</p>
            </div>
          </div>
          <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="200">
            <div className="block-style-fourteen mt-35">
              <h5>Our Vission</h5>
              <p>Except to obtain some advantage from it? But who has any right to find fault with a consequences data noise to intelligent insights.</p>
            </div>
          </div>
        </div>
      </div>
    </div>




    {/* <!--
    =====================================================
      Counter Section One
    =====================================================
    --> */}
    <div className="counter-section-one">
      <div className="inner-container bg-color style-two rounded-0 w-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 col-sm-6" data-aos="fade-up">
              <div className="counter-block-one text-center mb-20">
                <div className="main-count"><span className="counter">20</span>mil</div>
                <p>Human labor hours saved <br/> with help of AI</p>
              </div> 
            </div>
            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
              <div className="counter-block-one text-center mb-20">
                <div className="main-count"><span className="counter">1.3</span>b+</div>
                <p>Generated revenue by <br/>AI Solutions</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
              <div className="counter-block-one text-center mb-20">
                <div className="main-count">$<span className="counter">15</span>mil+</div>
                <p>Saved operational costs <br/>due to AI</p>
              </div>
            </div>
          </div>
        </div>
        <img src="assets/images/shape/shape_40.svg" alt="" className="shapes shape-three"/>
        <img src="assets/images/shape/shape_41.svg" alt="" className="shapes shape-four"/>
      </div>
    </div>



    {/* <!-- 
    =============================================
      Feature Section Five
    ============================================== 
    --> */}
    <div className="fancy-feature-five position-relative mt-50">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-4 col-lg-5 col-md-6">
            <div className="block-style-five pt-60 md-pt-20" data-aos="fade-right">
              <div className="title-style-one">
                <div className="sc-title-four">WHY CHOOSE US</div>
                <h2 className="main-title">Why you should choose us?</h2>
              </div>
              <p className="pt-10 pb-70">Tell us about your product and we will give you expert assistance.</p>
              <div className="skills-progress skills">
                <div className="habilidades_contenedor">
                      <div className="codeconSkills">
                          <div className="codeconSkillbar mb-80 md-mb-60">
                            <div className="skill-text">
                              <span className="codeconSkillArea">SKIll</span>
                            </div>
                              <div className="skillBar" data-percent="73%" style={{background: '#F25DFF'}}>
                                <span className="PercentText">73%</span>
                              </div>
                          </div>
                          <div className="codeconSkillbar">
                            <div className="skill-text">
                              <span className="codeconSkillArea ">Performance</span>
                                  
                            </div>
                            <div className="skillBar" data-percent="91%" style={{background: '#00E8FA'}}>
                                <span className="PercentText">91%</span>
                            </div>
                          </div>
                    </div> 
                  </div> 
              </div> 
            </div> 
          </div>

          <div className="col-xxl-8 col-lg-7 col-md-6 text-end">
            <div className="illustration-holder d-inline-block position-relative xs-mt-20">
              <img src="assets/images/assets/ils_06.svg" alt="" className="main-illustration w-100"/>
              <img src="assets/images/assets/ils_06_1.svg" alt="" className="shapes shape-one"/>
              <img src="assets/images/assets/ils_06_2.svg" alt="" className="shapes shape-two"/>
              <img src="assets/images/assets/ils_06_3.svg" alt="" className="shapes shape-three" data-aos="fade-down" data-aos-duration="1800"/>
              <img src="assets/images/assets/ils_06_4.svg" alt="" className="shapes shape-four" data-aos="fade-left" data-aos-duration="1800"/>
              <img src="assets/images/assets/ils_06_5.svg" alt="" className="shapes shape-five"/>
            </div>
          </div>
        </div>
      </div>
    </div> 

    {/* <!--
    =====================================================
      Address Section One
    =====================================================
    --> */}
    <div className="address-section-one">
      <div className="container">
        <div className="inner-content bg-white" data-aos="fade-up" data-aos-delay="100">
          <div className="row g-0">
            <div className="col-md-6 d-flex">
              <div className="address-block-one d-flex border-right">
                <div className="icon"><img src="assets/images/icon/icon_06.svg" alt=""/></div>
                <div className="text-meta">
                  <h4 className="title">Our Address</h4>
                  <p>1012 Pebda Parkway, Mirpur 2 <br/>Dhaka, Bangladesh</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="address-block-one d-flex">
                <div className="icon"><img src="assets/images/icon/icon_07.svg" alt=""/></div>
                <div className="text-meta">
                  <h4 className="title">Contact Info</h4>
                  <p>Open a chat or give us call at <br/><a href="/contact">310.841.5500</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Software