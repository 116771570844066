import React from 'react'

function Services() {
  return (
    <div>
      <div class="theme-inner-banner">
        <div class="container">
          <h2 class="intro-title text-center">Our Services</h2>
          <ul class="page-breadcrumb style-none d-flex justify-content-center">
            <li><a href="index.html">Home</a></li>
            <li class="current-page">Services</li>
          </ul>
        </div>
        <img src="assets/images/shape/shape_38.svg" alt="" class="shapes shape-one"/>
        <img src="assets/images/shape/shape_39.svg" alt="" class="shapes shape-two"/>
      </div> 

      
      

      {/* <!-- 
      =============================================
        Feature Section Twenty Four
      ============================================== 
      --> */}
      <div class="fancy-feature-twentyFour">
        <div class="container">
          <div class="row gx-xxl-5">
            <div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up">
              <div class="block-style-four">
                <div class="icon d-flex align-items-end justify-content-center"><img src="assets/images/icon/icon_35.svg" alt=""/></div>
                <a href="service-details-V1.html"><h5>Health Care</h5></a>
                <p>Convert data noise to intelligent insights for competitive differentiation.</p>
                <a href="service-details-V1.html" class="more-btn"><img src="assets/images/icon/icon_13.svg" alt="" class="tran3s"/></a>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="100">
              <div class="block-style-four">
                <div class="icon d-flex align-items-end justify-content-center"><img src="assets/images/icon/icon_08.svg" alt=""/></div>
                <a href="service-details-V1.html"><h5>Predictive Analytics</h5></a>
                <p>Convert data noise to intelligent insights for competitive differentiation.</p>
                <a href="service-details-V1.html" class="more-btn"><img src="assets/images/icon/icon_13.svg" alt="" class="tran3s"/></a>
              </div> 
            </div>
            <div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="200">
              <div class="block-style-four">
                <div class="icon d-flex align-items-end justify-content-center"><img src="assets/images/icon/icon_09.svg" alt=""/></div>
                <a href="service-details-V1.html"><h5>Data Engineers</h5></a>
                <p>Stay ahead of disruption & exceed customer expectation by implementing predictive analytics solutions.</p>
                <a href="service-details-V1.html" class="more-btn"><img src="assets/images/icon/icon_13.svg" alt="" class="tran3s"/></a>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="100">
              <div class="block-style-four">
                <div class="icon d-flex align-items-end justify-content-center"><img src="assets/images/icon/icon_10.svg" alt=""/></div>
                <a href="service-details-V1.html"><h5>Deep Learning</h5></a>
                <p>Access tools for deep learning, cloud computing and any scale.</p>
                <a href="service-details-V1.html" class="more-btn"><img src="assets/images/icon/icon_13.svg" alt="" class="tran3s"/></a>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="200">
              <div class="block-style-four">
                <div class="icon d-flex align-items-end justify-content-center"><img src="assets/images/icon/icon_11.svg" alt=""/></div>
                <a href="service-details-V1.html"><h5>Data Mining</h5></a>
                <p>You can discover hidden opportunities collecting, analyzing or explaining your data in a different way.</p>
                <a href="service-details-V1.html" class="more-btn"><img src="assets/images/icon/icon_13.svg" alt="" class="tran3s"/></a>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="300">
              <div class="block-style-four">
                <div class="icon d-flex align-items-end justify-content-center"><img src="assets/images/icon/icon_12.svg" alt=""/></div>
                <a href="service-details-V1.html"><h5>Statistical Modeling</h5></a>
                <p>Offer future-ready business applications that can learn & adjust with time.</p>
                <a href="service-details-V1.html" class="more-btn"><img src="assets/images/icon/icon_13.svg" alt="" class="tran3s"/></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- 
      =============================================
        Feature Section Nineteen
      ============================================== 
      --> */}
      <div class="fancy-feature-nineteen position-relative pt-160 lg-pt-100">
        <div class="container">
          <div class="row">
            <div class="col-xxl-5 col-lg-6 col-md-7">
              <div class="block-style-thirteen" data-aos="fade-right">
                <div class="title-style-three pb-15">
                  <div class="sc-title">WORK PROCESS</div>
                  <h2 class="main-title">How sinco <span>process</span> & works.</h2>
                </div>
                <ul class="style-none list-item">
                  <li data-aos="fade-up">
                    <div class="numb tran3s">1</div>
                    <h6>Frame the Problem</h6>
                    <span>Learn content by interacting with an expert lesson or watching a video.</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <div class="numb tran3s">2</div>
                    <h6>Collect the Data</h6>
                    <span>Practice what you learned on realistic SAT test questions.</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <div class="numb tran3s">3</div>
                    <h6>Process the Data</h6>
                    <span>Have to be repudiated annoyances accepted the wise lorem ispum sample text.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="illustration-holder" data-aos="fade-left">
          <img src="assets/images/assets/ils_15.svg" alt="" class="w-100 main-illustration"/>
          <img src="assets/images/assets/ils_15_1.svg" alt="" class="shapes shape-one"/>
          <img src="assets/images/assets/ils_15_2.svg" alt="" class="shapes shape-two"/>
          <img src="assets/images/assets/ils_15_3.svg" alt="" class="shapes shape-three"/>
          <img src="assets/images/assets/ils_15_4.svg" alt="" class="shapes shape-four"/>
          <img src="assets/images/assets/ils_15_5.svg" alt="" class="shapes shape-five" data-aos="fade-down" data-aos-delay="200" data-aos-duration="2000"/>
          <img src="assets/images/assets/ils_15_6.svg" alt="" class="shapes shape-six" data-aos="fade-down" data-aos-delay="100" data-aos-duration="2000"/>
          <img src="assets/images/assets/ils_15_7.svg" alt="" class="shapes shape-seven" data-aos="fade-down" data-aos-duration="2000"/>
        </div> 
        <div class="shapes oval-one"></div>
        <div class="shapes oval-two"></div>
        <img src="assets/images/shape/shape_47.svg" alt="" class="shapes bg-shape-two"/>
      </div>
    </div>
  )
}

export default Services